const operationNatureData = [
    {
        label: "Revenda",
        value: 0,
        // value: "REVENDA",
    },
    {
        label: "Consumo",
        value: 1,
        // value: "CONSUMO",
    },
    {
        label: "Revenda e Consumo",
        value: 2,
        // value: "REVENDA_E_CONSUMO",
    }
];

export default operationNatureData;